var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_vm.formReady ? _c('a', {
    on: {
      "click": function click($event) {
        _vm.formReady = false;
      }
    }
  }, [_vm._v(" Back ")]) : _c('a', {
    on: {
      "click": function click($event) {
        _vm.formReady = true;
      }
    }
  }, [_vm._v(" + New Upload ")])]), _vm.formReady ? _c('div', {
    staticClass: "mb-5"
  }, [_vm._v(" Mode: "), _vm.resource ? [_c('v-chip', [_vm._v(" updating #" + _vm._s(_vm.resource.id) + " ")]), _c('a', {
    staticClass: "ml-3",
    on: {
      "click": function click($event) {
        _vm.resource = null;
      }
    }
  }, [_vm._v("Switch to Upload")]), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("|")]), _c('a', {
    staticClass: "ml-3",
    on: {
      "click": function click($event) {
        return _vm.confirmDelete(_vm.resource);
      }
    }
  }, [_vm._v("Delete this media")])] : _c('v-chip', [_vm._v(" Upload ")])], 2) : _vm._e()]), _vm.formReady ? _c('div', {
    staticClass: "height-placeholder"
  }, [_c('media-form', {
    attrs: {
      "mode": _vm.resource ? 'update' : 'create',
      "resource": _vm.resource,
      "loading": _vm.loading,
      "disabled-fields": [_vm.resource ? 'file' : ''],
      "errors": _vm.errors
    },
    on: {
      "submit": _vm.handleSubmit,
      "cancel": function cancel($event) {
        _vm.resource = false;
      }
    }
  })], 1) : _vm._e(), !_vm.formReady ? _c('v-row', {
    staticClass: "mt-5"
  }, _vm._l(_vm.displayMedia, function (medium) {
    return _c('v-col', {
      key: medium.id,
      attrs: {
        "cols": "4"
      }
    }, [_c('v-badge', {
      scopedSlots: _vm._u([{
        key: "badge",
        fn: function fn() {
          return [_c('v-icon', {
            staticClass: "cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.confirmDelete(medium);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('img', {
      staticClass: "img img-thumbnail",
      attrs: {
        "src": medium.instance.thumbnail,
        "alt": ""
      }
    })]), _c('div', [medium.position === 'carousel' ? _c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiViewCarouselOutline) + " ")]), _c('v-badge', {
      attrs: {
        "content": medium.sort
      }
    }, [_c('small', [_vm._v(" Carousel ")])])], 1) : _vm._e(), medium.position === 'thumbnail' ? _c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiStar) + " ")]), _c('small', [_vm._v(" thumbnail ")])], 1) : _vm._e(), medium.device === 'mobile' ? _c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCellphone) + " ")]), _c('small', [_vm._v("Mobile")])], 1) : _vm._e(), medium.device === 'desktop' ? _c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDesktopMac) + " ")]), _c('small', [_vm._v("Desktop")])], 1) : _c('div', [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiAlphaPBox))]), _c('small', [_vm._v(" (general) ")])], 1)]), _c('v-btn', {
      on: {
        "click": function click($event) {
          return _vm.edit(medium);
        }
      }
    }, [_vm._v(" Edit ")])], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }