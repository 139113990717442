import axios from '@axios'

// currently it does not need to fetch article.
const fetchArticle = id => axios.get(`/admin/articles/${id}`)

const getArticles = params => axios.get('/admin/articles', { params })

const storeArticle = data => axios.post('/admin/articles', data)

const updateArticle = (id, data) => axios.put(`/admin/articles/${id}`, data)

const destroyArticle = id => axios.get(`/admin/articles/${id}`)

const handleArticleStatus = (id, action = 'publish') => axios.post(`/admin/articles/${id}/${action}`)

// === Article Tag ===

const getArticleTags = params => axios.get('/admin/article-tags', { params })

const storeArticleTag = data => axios.post('/admin/article-tags', data)

const updateArticleTag = (id, data) => axios.put(`/admin/article-tags/${id}`, data)

const destroyArticleTag = id => axios.get(`/admin/article-tags/${id}`)

// const getTags = params => axios.get('/admin/tags', { params })

// --- Media ---

const storePhoto = data => axios.post('/admin/article-media/photos', data)

const updatePhoto = (id, data) => axios.put(`/admin/article-media/${id}`, data)

const destroyPhoto = id => axios.delete(`/admin/article-media/${id}`)

export {
  fetchArticle,
  getArticles,
  storeArticle,
  updateArticle,
  destroyArticle,
  handleArticleStatus,
  // === Article Tag ===
  getArticleTags,
  storeArticleTag,
  updateArticleTag,
  destroyArticleTag,
  // === Article Media ===
  storePhoto,
  updatePhoto,
  destroyPhoto,
}
