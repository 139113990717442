var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-view"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v("Back")])])], 1), _vm.articleData ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Article")]), _c('v-card-text', [_c('h2', {
    staticClass: "mt-2"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.articleData.title)) + " "), _c('v-chip', {
    attrs: {
      "color": _vm.getStatusColor(_vm.articleData.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.articleData.status) + " ")])], 1), _c('p', [_c('b', [_vm._v(_vm._s(_vm.articleData.slug))])]), _c('div', {
    staticClass: "mt-2 content",
    domProps: {
      "innerHTML": _vm._s(_vm.t(_vm.articleData.content))
    }
  })])], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v("Article Media")]), _vm.articleData ? _c('v-card-text', [_vm.articleData ? _c('ArticleMediaGallery', {
    attrs: {
      "article": _vm.articleData
    },
    on: {
      "updated": _vm.reloadArticle
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Display tags")]), _vm.editingManifest ? _c('a', {
    on: {
      "click": _vm.reloadArticle
    }
  }, [_vm._v("Done")]) : _c('a', {
    on: {
      "click": function click($event) {
        _vm.editingManifest = true;
      }
    }
  }, [_vm._v("Edit")])]), _vm.articleData ? _c('v-card-text', [_vm.editingManifest ? [_vm.articleData ? _c('sync-tag-form', {
    attrs: {
      "col-size": 12,
      "selected-ids": _vm.articleData.manifest_tags.map(function (c) {
        return c.id;
      }),
      "sync-base-path": "/admin/articles/".concat(_vm.articleData.id, "/manifest-tags/sync")
    }
  }) : _vm._e(), _c('p', [_vm._v("Tags will be automatically updated.")])] : [_c('p', [_vm._v("Tags which are displayed along with thumbnail (cover). Users DO NOT expect to click it.")]), _vm._l(_vm.articleData.manifest_tags, function (tag) {
    return _c('v-chip', {
      key: tag.id
    }, [_vm._v(" " + _vm._s(_vm.t(tag.name)) + " ")]);
  })]], 2) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Searchable tags")]), _vm.editingTag ? _c('a', {
    on: {
      "click": _vm.reloadArticle
    }
  }, [_vm._v("Done")]) : _c('a', {
    on: {
      "click": function click($event) {
        _vm.editingTag = true;
      }
    }
  }, [_vm._v("Edit")])]), _vm.articleData ? _c('v-card-text', [_vm.editingTag ? [_vm.articleData ? _c('sync-tag-form', {
    attrs: {
      "col-size": 12,
      "selected-ids": _vm.articleData.tags.map(function (c) {
        return c.id;
      }),
      "sync-base-path": "/admin/articles/".concat(_vm.articleData.id, "/tags/sync")
    }
  }) : _vm._e(), _c('p', [_vm._v("Tags will be automatically updated.")])] : [_c('p', [_vm._v("Tags which are searchable and filterable. These are visible when an user views article details. And user expects to click it.")]), _vm._l(_vm.articleData.tags, function (tag) {
    return _c('v-chip', {
      key: tag.id
    }, [_vm._v(" " + _vm._s(_vm.t(tag.name)) + " ")]);
  })]], 2) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_vm._v("Visible by Distributors")]), _c('v-chip', [_vm._v(" " + _vm._s(_vm.articleData.public ? 'PUBLIC' : 'PRIVATE') + " ")])], 1), _vm.articleData ? _c('v-card-text', [_c('p', [_c('b', [_vm._v("Leave empty")]), _vm._v(" for public access. Or enable a few distributors for their users to access privately.")]), _vm.articleData ? _c('sync-distributor-form', {
    attrs: {
      "col-size": 12,
      "selected-ids": _vm.articleData.enabled_distributors.map(function (c) {
        return c.id;
      }),
      "sync-base-path": "/admin/articles/".concat(_vm.articleData.id, "/enabled-distributors/sync")
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "mb-2"
  }, [_c('v-card-title', [_vm._v("Information")]), _vm.articleData ? _c('v-card-text', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Created At")]), _c('span', [_vm._v(_vm._s(_vm._f("dateTime")(_vm.articleData.created_at)))])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Published At")]), _c('span', [_vm._v(_vm._s(_vm._f("dateTime")(_vm.articleData.published_at)))])])]) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v("Actions")]), _vm.articleData ? _c('v-card-text', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['published'].includes(_vm.articleData.status),
      expression: "['published'].includes(articleData.status)"
    }],
    staticClass: "mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.unpublishArticle(_vm.articleData.id).then(_vm.reloadArticle);
      }
    }
  }, [_vm._v(" Unpublish ")]), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['draft', 'unpublished'].includes(_vm.articleData.status),
      expression: "['draft', 'unpublished'].includes(articleData.status)"
    }],
    staticClass: "mr-2",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function click($event) {
        _vm.publishArticle(_vm.articleData.id).then(_vm.reloadArticle);
      }
    }
  }, [_vm._v(" Publish ")]), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.aside = true;
      }
    }
  }, [_vm._v(" Edit ")])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.aside ? _c('article-aside', {
    attrs: {
      "resource": _vm.articleData
    },
    on: {
      "changed": function changed($event) {
        _vm.reloadArticle();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }