<template>
  <v-form ref="formElem">
    <div>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-if="!disabledFields.includes('file')"
            v-model="form.file"
            :loading="loading"
            accept="image/*"
            label="Choose File"
          >
          </v-file-input>

          <v-select
            v-model="form.position"
            placeholder="Display Section"
            :items="positionOptions"
            item-text="title"
            item-value="value"
            :prepend-icon="icons.mdiViewQuilt"
            outlined
            dense
          ></v-select>

          <v-select
            v-model="form.device"
            placeholder="Device"
            :items="deviceOptions"
            item-text="title"
            item-value="value"
            :prepend-icon="icons.mdiCellphoneLink"
            outlined
            dense
          ></v-select>

          <v-text-field
            v-model="form.sort"
            label="Sort Order (ascending)"
            hint="A smaller number means it will display in the front of the carousel."
            :rules="[between(form.sort,0,999)]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        :loading="loading"
        @click="validate"
      >
        Submit
      </v-btn>
      <v-btn
        v-if="resource"
        :loading="loading"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        API error: {{ msg }}
      </v-alert>
    </div>
  </v-form>
</template>

<script>
import { mdiCellphoneLink, mdiViewQuilt } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { between, required } from '@core/utils/validation'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      file: null,
      device: props.resource ? props.resource.device : 'universal',
      position: props.resource ? props.resource.position : 'thumbnail',
      sort: props.resource ? props.resource.sort : 10,
    }

    console.log('init form', initialForm)

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const deviceOptions = [
      { title: 'All Device', value: 'universal' },
    ]

    const positionOptions = [
      { title: 'Thumbnail', value: 'thumbnail' },
      { title: 'Carousel', value: 'carousel' },
    ]

    return {
      form,
      icons: {
        mdiCellphoneLink,
        mdiViewQuilt,
      },

      formElem,
      validate,
      required,
      between,

      deviceOptions,
      positionOptions,
    }
  },
}
</script>
