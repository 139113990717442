<template>
  <div>
    <!-- <v-card-title>
        <h1>Article Media Gallery</h1>
      </v-card-title> -->
    <div class="d-flex justify-space-between">
      <!-- <div
          v-if="article"
          class="mb-5"
        >
          article: {{ article.title.en }}
        </div> -->
      <div>
        <a
          v-if="formReady"
          @click="formReady = false"
        >
          Back
        </a>

        <a
          v-else
          @click="formReady = true"
        >
          + New Upload
        </a>
      </div>

      <div
        v-if="formReady"
        class="mb-5"
      >
        Mode:
        <template
          v-if="resource"
        >
          <v-chip>
            updating #{{ resource.id }}
          </v-chip>
          <a
            class="ml-3"
            @click="resource = null"
          >Switch to Upload</a>
          <span class="ml-3">|</span>
          <a
            class="ml-3"
            @click="confirmDelete(resource)"
          >Delete this media</a>
        </template>
        <v-chip v-else>
          Upload
        </v-chip>
      </div>
    </div>

    <div
      v-if="formReady"
      class="height-placeholder"
    >
      <media-form
        :mode="resource ? 'update' : 'create'"
        :resource="resource"
        :loading="loading"
        :disabled-fields="[ resource ? 'file' : '']"
        :errors="errors"
        @submit="handleSubmit"
        @cancel="resource = false"
      />
    </div>

    <v-row
      v-if="!formReady"
      class="mt-5"
    >
      <v-col
        v-for="medium in displayMedia"
        :key="medium.id"
        cols="4"
      >
        <v-badge>
          <template #badge>
            <v-icon
              class="cursor-pointer"
              @click="confirmDelete(medium)"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </template>
          <img
            :src="medium.instance.thumbnail"
            alt=""
            class="img img-thumbnail"
          >
        </v-badge>

        <div>
          <div v-if="medium.position === 'carousel'">
            <v-icon>
              {{ icons.mdiViewCarouselOutline }}
            </v-icon>
            <v-badge
              :content="medium.sort"
            >
              <small>
                Carousel
              </small>
            </v-badge>
          </div>

          <div v-if="medium.position === 'thumbnail'">
            <v-icon>
              {{ icons.mdiStar }}
            </v-icon>
            <small>
              thumbnail
            </small>
          </div>

          <div v-if="medium.device === 'mobile'">
            <v-icon>
              {{ icons.mdiCellphone }}
            </v-icon>
            <small>Mobile</small>
          </div>

          <div v-if="medium.device === 'desktop'">
            <v-icon>
              {{ icons.mdiDesktopMac }}
            </v-icon>
            <small>Desktop</small>
          </div>

          <div v-else>
            <v-icon>{{ icons.mdiAlphaPBox }}</v-icon>
            <small>
              (general)
            </small>
          </div>
        </div>

        <v-btn @click="edit(medium)">
          Edit
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
useConfirm,
useExtractErrors,
useNotifyErrors,
useNotifySuccess,
} from '@/composables'
import { t } from '@/plugins/i18n'
import { destroyPhoto, storePhoto, updatePhoto } from '@api/blog'
import {
mdiAlphaPBox, mdiAlphaSBox, mdiCellphone, mdiClose, mdiDesktopMac, mdiStar, mdiViewCarouselOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import MediaForm from './ArticleMediaForm.vue'

export default {
  components: { MediaForm },

  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const articleId = props.article.id

    const loading = ref(false)
    const formReady = ref(false)
    const resource = ref(false)

    const errors = ref([])

    const handleStore = form => {
      const formData = new FormData()

      Object.entries(form).forEach(([key, value]) => {
        if (value) formData.append(key, value)
      })

      formData.append('article_id', articleId)

      return storePhoto(formData)
    }

    const handleUpdate = form => updatePhoto(resource.value.id, { ...form })

    const handleSubmit = async form => {
      loading.value = true
      errors.value = []

      const action = resource.value ? handleUpdate : handleStore

      await action(form)
        .then(() => {
          emit('updated')
          useNotifySuccess({ content: 'Media Updated.' })
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })

      loading.value = false
    }

    const refreshForm = () => {
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const displayMedia = computed(() => props.article.media)

    const edit = medium => {
      resource.value = medium
      refreshForm()
    }

    const confirmDelete = async medium => {
      await useConfirm({
        title: 'Confirm Action',
        content: 'Confirm delete this media?',
      })

      destroyPhoto(medium.id)
        .then(() => {
          emit('updated')
        })
        .catch(useNotifyErrors)
    }

    return {
      errors,
      loading,
      handleSubmit,

      resource,
      displayMedia,
      edit,
      formReady,

      confirmDelete,
      t,
      icons: {
        mdiClose,
        mdiAlphaSBox,
        mdiAlphaPBox,
        mdiViewCarouselOutline,
        mdiStar,
        mdiCellphone,
        mdiDesktopMac,
      },
    }
  },
}
</script>

  <style scoped>
  .img {
    max-width: 100%;
  }
  .height-placeholder {
    min-height: 360px;
  }
  </style>
