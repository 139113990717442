var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Slug",
      "hint": "https://app.mixcarehealth.com/blog/posts/{slug}",
      "outlined": ""
    },
    model: {
      value: _vm.form.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slug", $$v);
      },
      expression: "form.slug"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Article Title",
      "outlined": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Article HTML Content",
      "outlined": "",
      "eidtor-options": {
        uploadUrl: 'article',
        postUuid: _vm.form.post_uuid
      }
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Short Description or Summary.",
      "outlined": ""
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('p', [_vm._v("Published Date")]), _c('v-date-picker', {
    model: {
      value: _vm.form.published_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "published_at", $$v);
      },
      expression: "form.published_at"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }