<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.key"
          label="Unique Key / Slug"
          hint="Article Tag unique key"
          outlined
          class="mt-5"
        />

        <translatable-input
          v-model="form.name"
          label="Tag Name"
          outlined
          class="mt-5"
        />

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            :loading="loading"
            @click="validate"
          >
            Submit
          </v-btn>
        </v-card-actions>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { between, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales, TranslatableInput },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('key', ''),
      ...field('name', useTranslatable('name')),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
      between,
    }
  },
}
</script>
