<template>
  <div>
    <article-tag-aside
      v-if="making"
      :active="true"
      @updated="made"
      @aside="bol => { if(!bol) { making = false } }"
    ></article-tag-aside>
    <v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col :cols="colSize">
              <v-select
                v-model="form.sync_ids"
                placeholder="Select 0 or more tags"
                :items="tagsOptions"
                item-text="title"
                item-value="value"
                label="Tags"
                outlined
                chips
                multiple
                hide-details
                @change="handleSync"
              ></v-select>
            </v-col>
            <v-col :cols="colSize">
              <!-- <v-select
                v-model="form.type"
                placeholder="Display Section"
                :items="typeOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
              ></v-select> -->
              <v-text-field
                v-model="searchText"
                placeholder="Search Tag key, name"
              >
                <template v-slot:append>
                  <a @click="making = true">+new</a>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import blogStore from '@/modules/blog/store'
import store from '@/store'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import ArticleTagAside from './ArticleTagAside.vue'

export default {
  components: { ArticleTagAside },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    colSize: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-tags
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    // const typeOptions = [
    //   { title: 'Service Category', value: 'service' },
    //   { title: 'Tangible Category', value: 'tangible' },
    // ]
    if (!blogStore.state.tags.length) {
      store.dispatch('blog/fetchAllTags', {
        take: 999,
      })
    }

    const searchText = ref('')

    const matchText = tag => {
      const q = searchText.value.toLowerCase()
      const key = tag.key.toLowerCase()
      const name = JSON.stringify(tag.name).toLowerCase()

      return key.includes(q) || name.includes(q) || props.selectedIds.includes(tag.id)
    }

    const tagsOptions = computed(() => blogStore.state.tags.filter(matchText).map(tag => ({ title: tag.name.en, value: tag.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('changed')

        return Promise.resolve(res)
      })
    }

    const making = ref(false)
    const made = async resource => {
      console.log('made resource', resource)

      form.value.sync_ids.push(resource.id)
      await handleSync()

      making.value = false
    }

    return {
      form,
      searchText,

      // typeOptions,
      tagsOptions,
      handleSync,

      making,
      made,
    }
  },
}
</script>
