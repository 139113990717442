<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>
      </v-col>
    </v-row>

    <v-row v-if="articleData">
      <v-col
        cols="8"
      >
        <v-card>
          <v-card-title>Article</v-card-title>

          <v-card-text>
            <h2 class="mt-2">
              {{ t(articleData.title) }}

              <v-chip :color="getStatusColor(articleData.status)">
                {{ articleData.status }}
              </v-chip>
            </h2>

            <p>
              <b>{{ articleData.slug }}</b>
            </p>

            <div
              class="mt-2 content"
              v-html="t(articleData.content)"
            />
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Article Media</v-card-title>

          <v-card-text v-if="articleData">
            <ArticleMediaGallery
              v-if="articleData"
              :article="articleData"
              @updated="reloadArticle"
            />
          </v-card-text>
        </v-card>

        <!-- Manifest Tags -->
        <v-card class="mt-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Display tags</span>
            <a
              v-if="editingManifest"
              @click="reloadArticle"
            >Done</a>
            <a
              v-else
              @click="editingManifest = true"
            >Edit</a>
          </v-card-title>

          <v-card-text v-if="articleData">
            <template v-if="editingManifest">
              <sync-tag-form
                v-if="articleData"
                :col-size="12"
                :selected-ids="articleData.manifest_tags.map(c => c.id)"
                :sync-base-path="`/admin/articles/${articleData.id}/manifest-tags/sync`"
              />
              <p>Tags will be automatically updated.</p>
            </template>

            <template v-else>
              <p>Tags which are displayed along with thumbnail (cover). Users DO NOT expect to click it.</p>
              <v-chip
                v-for="tag in articleData.manifest_tags"
                :key="tag.id"
              >
                {{ t(tag.name) }}
              </v-chip>
            </template>
          </v-card-text>
        </v-card>

        <!-- Searchable Tags -->
        <v-card class="mt-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Searchable tags</span>
            <a
              v-if="editingTag"
              @click="reloadArticle"
            >Done</a>
            <a
              v-else
              @click="editingTag = true"
            >Edit</a>
          </v-card-title>

          <v-card-text v-if="articleData">
            <template v-if="editingTag">
              <sync-tag-form
                v-if="articleData"
                :col-size="12"
                :selected-ids="articleData.tags.map(c => c.id)"
                :sync-base-path="`/admin/articles/${articleData.id}/tags/sync`"
              />
              <p>Tags will be automatically updated.</p>
            </template>

            <template v-else>
              <p>Tags which are searchable and filterable. These are visible when an user views article details. And user expects to click it.</p>
              <v-chip
                v-for="tag in articleData.tags"
                :key="tag.id"
              >
                {{ t(tag.name) }}
              </v-chip>
            </template>
          </v-card-text>
        </v-card>

        <!-- Distributors -->
        <v-card class="mt-4">
          <v-card-title class="d-flex justify-space-between">
            <div>Visible by Distributors</div>
            <v-chip>
              {{ articleData.public ? 'PUBLIC' : 'PRIVATE' }}
            </v-chip>
          </v-card-title>

          <v-card-text v-if="articleData">
            <p><b>Leave empty</b> for public access. Or enable a few distributors for their users to access privately.</p>
            <sync-distributor-form
              v-if="articleData"
              :col-size="12"
              :selected-ids="articleData.enabled_distributors.map(c => c.id)"
              :sync-base-path="`/admin/articles/${articleData.id}/enabled-distributors/sync`"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="4"
      >
        <v-card class="mb-2">
          <v-card-title>Information</v-card-title>

          <v-card-text v-if="articleData">
            <div class="d-flex justify-space-between">
              <span>Created At</span>
              <span>{{ articleData.created_at | dateTime }}</span>
            </div>

            <div class="d-flex justify-space-between">
              <span>Published At</span>
              <span>{{ articleData.published_at | dateTime }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Actions</v-card-title>

          <v-card-text
            v-if="articleData"
            class="d-flex justify-center"
          >
            <v-btn
              v-show="['published'].includes(articleData.status)"
              color="error"
              class="mr-2"
              @click="unpublishArticle(articleData.id).then(reloadArticle)"
            >
              Unpublish
            </v-btn>

            <v-btn
              v-show="['draft', 'unpublished'].includes(articleData.status)"
              color="success"
              class="mr-2"
              @click="publishArticle(articleData.id).then(reloadArticle)"
            >
              Publish
            </v-btn>

            <v-btn
              color="info"
              class="mr-2"
              @click="aside = true"
            >
              Edit
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <article-aside
      v-if="aside"
      v-model="aside"
      :resource="articleData"
      @changed="
        reloadArticle()
        aside = false
      "
    />
  </div>
</template>

<script>
import SyncDistributorForm from '@/modules/distributor/components/distributor/SyncDistributorForm.vue'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateTime } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import ArticleAside from '../article-resource/ArticleAside.vue'
import SyncTagForm from '../article-tag-resource/SyncTagForm.vue'
import ArticleMediaGallery from '../media-resource/ArticleMediaGallery.vue'
import useArticleView from './useArticle'

export default {
  components: {
    SyncDistributorForm,
    ArticleAside,
    SyncTagForm,
    ArticleMediaGallery,
  },

  filters: {
    dateTime,
  },

  setup() {
    const {
      getArticle, articleData, publishArticle, unpublishArticle,
    } = useArticleView()

    const aside = ref(false)
    const editingManifest = ref(false)
    const editingTag = ref(false)

    const reloadArticle = () => {
      articleData.value = null
      editingManifest.value = false
      editingTag.value = false

      return getArticle(router.currentRoute.params.id).then()
    }

    onMounted(() => reloadArticle().then())

    const getStatusColor = status => {
      let color = 'info'
      switch (status) {
        case 'published':
          color = 'success'
          break
        case 'unpublished':
          color = ''
          break

        default:
      }

      return color
    }

    return {
      aside,
      editingManifest,
      editingTag,

      getStatusColor,
      publishArticle,
      unpublishArticle,

      articleData,
      reloadArticle,
      t,
    }
  },
}
</script>

<style lang="scss">
.content {
  img {
    max-width: 100%;
  }
}
</style>
