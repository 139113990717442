import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import store from '@/store'
import { destroyArticle, fetchArticle, handleArticleStatus } from '@api/blog'
import { ref } from '@vue/composition-api'

export default function useArticleView() {
  const articleData = ref(null)
  const loading = ref(false)

  // fetch data
  const getArticle = id => {
    articleData.value = null
    loading.value = true

    return fetchArticle(id)
      .then(res => {
        articleData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashArticle = id => destroyArticle(id).catch(useNotifyErrors)

  const confirmTrashArticle = article =>
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash article: <b>${t(article.title)}</b> ?`,
          resolve: () => trashArticle(article.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const publishArticle = id => handleArticleStatus(id, 'publish').catch(useNotifyErrors)

  const unpublishArticle = id => handleArticleStatus(id, 'unpublish').catch(useNotifyErrors)

  return {
    loading,

    articleData,

    getArticle,
    trashArticle,
    confirmTrashArticle,

    publishArticle,
    unpublishArticle,
  }
}
