var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.making ? _c('article-tag-aside', {
    attrs: {
      "active": true
    },
    on: {
      "updated": _vm.made,
      "aside": function aside(bol) {
        if (!bol) {
          _vm.making = false;
        }
      }
    }
  }) : _vm._e(), _c('v-form', [_c('v-card', [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": _vm.colSize
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "Select 0 or more tags",
      "items": _vm.tagsOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Tags",
      "outlined": "",
      "chips": "",
      "multiple": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.handleSync
    },
    model: {
      value: _vm.form.sync_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sync_ids", $$v);
      },
      expression: "form.sync_ids"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": _vm.colSize
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Search Tag key, name"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('a', {
          on: {
            "click": function click($event) {
              _vm.making = true;
            }
          }
        }, [_vm._v("+new")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1)], 1)], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }