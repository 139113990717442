var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.disabledFields.includes('file') ? _c('v-file-input', {
    attrs: {
      "loading": _vm.loading,
      "accept": "image/*",
      "label": "Choose File"
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  }) : _vm._e(), _c('v-select', {
    attrs: {
      "placeholder": "Display Section",
      "items": _vm.positionOptions,
      "item-text": "title",
      "item-value": "value",
      "prepend-icon": _vm.icons.mdiViewQuilt,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.position,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "position", $$v);
      },
      expression: "form.position"
    }
  }), _c('v-select', {
    attrs: {
      "placeholder": "Device",
      "items": _vm.deviceOptions,
      "item-text": "title",
      "item-value": "value",
      "prepend-icon": _vm.icons.mdiCellphoneLink,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.device,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "device", $$v);
      },
      expression: "form.device"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Sort Order (ascending)",
      "hint": "A smaller number means it will display in the front of the carousel.",
      "rules": [_vm.between(_vm.form.sort, 0, 999)]
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  })], 1)], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")]), _vm.resource ? _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" API error: " + _vm._s(msg) + " ")]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }